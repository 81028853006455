/*
 * Foundational things
 */

@import "./normalize";
@import "./mallory-compact/mallory-compact";
// @import "./griffith-gothic/griffith-gothic";

@mixin small-screen-only {
  @media (max-width: 699px) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin xl-screen {
  @media (min-width: 1300px) {
    @content;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.sr-only {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

/*
 * Base styles
 */

html {
  font-size: 20px;
  @include medium-screen {
    font-size: 22px;
  }
  @include large-screen {
    font-size: 27px;
  }
  @include xl-screen {
    font-size: 40px;
  }
}

$font-base: "Mallory Compact";
$font-weight-regular: 300;
$font-weight-strong: 700;
$text-letter-spacing: -0.01em;
$font-size-normal: 1.4em;
$font-size-aside: 1.3em;
$font-size-aside-large: 1.1em;

body {
  font-family: $font-base, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  background: white;
  color: black;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  &.transition-active {
    transition: background-color 0.5s ease;
  }
}

body.transition-active .color-shift {
  transition: color 0.5s ease;
}

$link-border-width: 0.1em;
$link-border-width-hover: 0.2em;

a {
  text-decoration-line: underline;
  text-decoration-thickness: $link-border-width;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-color: rgba(0, 0, 0, 0.2);
  transition: text-decoration-thickness 0.1s ease-in-out;
  &:hover {
    text-decoration-thickness: $link-border-width-hover;
  }
}

main {
  margin-top: 2.1em;
  margin-bottom: 4em;
  margin-left: 1.25em;
  margin-right: 1.25em;
  @include medium-screen {
    max-width: 27em;
    margin-left: 2em;
    margin-right: 2em;
  }
}

p,
li,
input[type="text"] {
  font-size: $font-size-normal;
  line-height: 1.3;
  letter-spacing: $text-letter-spacing;
}

p {
  margin-top: 0;
  margin-bottom: 1.2em;
}

li {
  margin-bottom: 0.5em;
}

strong {
  font-weight: $font-weight-strong;
}

/*
 *  Specific styles
 */

#pronouns {
  vertical-align: middle;
  font-size: 0.6em;
}

#spacer {
  border: none;
  height: 6em;
}

#favorite-color {
  input[type="text"] {
    max-width: 6.5em;
    border: none;
    background: transparent;
    padding: 0;
    margin-top: 0.1em;
    border-radius: 0;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    transition: width 0.3s ease;
    @include medium-screen {
      padding-left: 0.1em;
    }
  }
  input[type="submit"] {
    appearance: none;
    background: none;
    border-style: solid;
    border-width: $link-border-width;
    transition: color 0.5s ease, border-color 0.5s ease,
      border-width 0.1s ease-in-out;
    border-radius: 99px;
    padding: 0;
    width: 2em;
    height: 2em;
    font-size: 1em;
    text-align: center;
    vertical-align: super;
    cursor: pointer;
    &:hover {
      border-width: $link-border-width-hover;
    }
  }
}

#color-response {
  p {
    opacity: 0.85;
    margin-top: 1em;
    font-size: $font-size-aside;
    @include large-screen {
      font-size: $font-size-aside-large;
    }
  }
}
