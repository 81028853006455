@font-face {
  font-family: "Mallory Compact";
  font-weight: 300;
  src: url("./styles/mallory-compact/MalloryCmpct-Light.woff2") format("woff2"),
    url("./styles/mallory-compact/MalloryCmpct-Light.woff") format("woff");
}

@font-face {
  font-family: "Mallory Compact";
  font-weight: 700;
  src: url("./styles/mallory-compact/MalloryCmpct-Bold.woff2") format("woff2"),
    url("./styles/mallory-compact/MalloryCmpct-Bold.woff") format("woff");
}
